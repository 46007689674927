.modal-content {
    height: 97%;
    border-radius: 0 !important;
    background-color: #fcf8f0 !important;
    position: fixed !important;
    top: 0;
    right: 0 !important;
    bottom: 1em;
    max-width: 400px;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-right: 1em;
    border: 1px solid #23231a !important;
    z-index: 11;
}

.flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 97%;
}

.modal-header {
    border-bottom: 1px solid #23231a !important;
    border-radius: 0 !important;
}

.shopping-bag {
    background-color: transparent;
    border: none;
}

.product-count {
    background-color: #ffc43c;
    padding: .20em .40em;
    color: #070707;
    font-family: neue-haas-grotesk-display, sans-serif;
    font-weight: 600;
    border-radius: 50%;
}

.checkout-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    justify-content: end;
    align-items: center;
    gap: 1rem;
}

.checkout-btn {
    border: 1px solid #ffc33c;
    background-color: #ffd778;
    text-align: center;
    width: 100%;
    margin-left: 1em;
    margin-right: 1em;
    font-family: neue-haas-grotesk-display, sans-serif;
    font-size: 1em;
    transform: rotate(-1deg);
}
.checkout-btn:hover{
    background-color: #dbb761;
}
.no-items {
    padding: .75rem
}

.nav-container {
    z-index: 10;
}