a {
    text-decoration: none;
    color: inherit;
}

ul {
    list-style-type: none;
    margin: 0;
}


.shippingPolicy {
    padding: 2rem;
    max-width: 700px;
    align-items: center;
    justify-content: center;
}

.footer-text-container {
    padding-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

}

.container-p {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-size: 54px;
    font-weight: 400;
    line-height: 60px;
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 40px;
    margin-top: 40px;
    padding: 1rem;
}


.purple-btn {
    background-color: #8769fa;
    color: #fcf8f0;
    text-align: center;
    border: 2px solid #000;
    width: 80%;
    padding: 6px 0;
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 1rem;
    font-family: neue-haas-grotesk-display, sans-serif;
    transition: transform 0.3s ease-in-out;
    font-size: 1rem;
    transform: rotate(-1deg);
    max-height: 44px;
    align-items: center;
    justify-content: center;
    display: flex;
    text-decoration: none;
    line-height: 1rem;
    /* Remove underline */
}

.purple-btn:hover {
    transform: rotate(+1deg);
}

.no-rotate {
    transform: rotate(0deg);
}

.indent {
    text-indent: .5rem;
}

.purple {
    color: #8769fa
}

.farsan-text {
    font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
    font-display: swap;
}
.form-label-filters{
    font-size: .8rem;
}

.footer-container {
    display: flex;
    height: fit-content;
    width: 100%;
    background-color: #8769fa;
    border-top: 2px solid #000;
    bottom: 0;
    justify-content: center;
    align-items: center;
    color: black;
}

.footer-wrapper {
    display: flex;
    width: 100%;
}


.nav-container {
    border-bottom: 2px solid #000;
    padding: 8px 20px;
    background-color: rgba(252, 248, 240, .6);
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    max-height: 64px;
}

.mt-64 {
    margin-top: 64px
}

.ve-stamp {
    max-width: 200px;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.pb-6 {
    padding-bottom: 6px;
}

.category-container {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}

.outline {
    border: 2px solid black
}

.bg-image {
    height: 650px;
}



.carbon-container {
    display: flex;

    background-color: #FFE381;

    padding: 2rem;
    border-radius: 16px;
    justify-content: space-evenly;
    margin: 64px 0;
    flex-direction: row;
}

.earth-img {
    max-width: 400px;
    z-index: 2;

}

.text-wrap {
    text-wrap: wrap;

}

.img-wrapper {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-wrapper {
    width: 45%;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
    margin-right: 5%;
    z-index: 2;
}

.relative-container {
    position: relative;

}

.flowers-frame {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 20px;
    opacity: 30%;
    border: 2px solid black;
}


.form-subscribe-container {
    background-color: #FFE381;
    padding: 3rem;
    max-width: 700px;
    border-radius: 20px;
    border: 1px solid black;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
}

.label-email {
    margin-right: 1rem;
}

.form-contact-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    padding: 2rem;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}

.cart-product {
    display: flex;
    flex-direction: column;
}

.product-container {
    display: flex;
    gap: 1rem;
}

.input-form-container {
    width: 100%;
}

.input-form {
    width: 100%;
    text-indent: 1rem;
    margin-bottom: 11px;
    font-size: 14px;
    padding: 15px 30px;
    border: 0;
    border-radius: 20px;
    color: #626262;
    background: #e0e0e0;
}

.card-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-top: 1rem;
    min-height: 30px;
}


.h-img-1:hover {
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, .2));
}

.size-text {
    color: rgb(0, 0, 0);
    padding: 4px;
    font-size: 14px;
    font-weight: 700;
}

.banner-container{
    width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 1rem;
}
.banner-img{
    max-width: 750px;
    height: auto;
    border: 2px black solid
}

.banner-mobile-img{
    display: none
}

.form-control:hover{
    cursor: pointer;
}

.form-container-filters{
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 1rem;
    justify-content: end;
    margin-top: 2rem;

}
.filter-selection{
   width: 80px;
    
}

@media (max-width: 768px) {
    .bg-image {
        height: 500px;
        padding-left: 2rem;
    }
    .form-container-filters{
        justify-content: center;
    }

    .carbon-container {
        flex-direction: column;
    }

    .text-wrapper,
    .img-wrapper {
        width: 100%;
    }

    .text-wrapper {
        text-align: center;
        margin-top: 1rem;
        font-weight: 600;
    }

    .ve-stamp {
        width: 100px;
    }

    .footer {
        padding-top: 1rem;
    }

    .form-subscribe-container {
        flex-direction: column;
        text-align: center;
        margin-bottom: 1rem;
    }

    .card-title {
        min-height: 60px;
    }
}

@media (max-width: 700px){
    .banner-mobile-img{
        max-width: 600px;
        width: 100%;
        padding: 1rem;
        display: flex;
        height: auto;
    }
    .banner-img{display:none}


}