.single-product-page {
    display: flex;
    margin-top: 64px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    overflow: hidden;
}

.image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem;
    max-width: 100%;

}

.main-image img {
    max-width: 400px;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.thumbnails {
    display: flex;
    gap: .6rem;
    margin-bottom: 20px;
    max-width: 100%;
    flex-wrap: wrap;
}

.thumbnail {
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.thumbnail:hover {
    transform: scale(1.05);
}

.product-info {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-top: 64px;
    max-width: 600px;
}

.product-info h1 {
    margin-bottom: 10px;
}

.product-info p {
    margin-bottom: 5px;
}

.btn-container {
    display: flex;
}

.white-btn {
    background-color: #FFC43C;
    color: black;
}

.white-btn:hover {
    background-color: white;
}

.disqus-container {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    padding: 1rem;
}

.brand-title {
    font-size: 18px;
    color: #8769FA;
    font-weight: 700;
}

@media (max-width: 600px) {
    .product-info {
        margin-top: 0;
    }

    .single-product-page {
        padding: 1rem;
    }

    .thumbnails {
        justify-content: center;
        gap: 2rem;
    }


}